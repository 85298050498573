var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.Headers,"items":_vm.Items,"hide-default-footer":!_vm.ToLinked,"item-class":_vm.getClassColor,"options":_vm.pagination,"server-items-length":_vm.TotalItems,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': 'Itens por página',
            'show-first-last-page': true,
            'page-text': `Página ${_vm.pagination.page} de ${
                parseInt(_vm.TotalItems / _vm.pagination.itemsPerPage) + 1
            }`,
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:`item.value`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.value)))])]}},{key:`item.date`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:`item.moviment_type`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getTypeMoviment(item.moviment_type)))])]}},{key:`item.status`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getStatus(item.status)))])]}},{key:`item.type`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getType(item.type)))])]}},(_vm.ToLinked)?{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.link(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-link-variant")])]}}],null,true)},[_c('span',[_vm._v("Vincular")])])]}}:null,{key:`item.ligations`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatLigations(item.linked_to, item.total_vinculations)))])]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }