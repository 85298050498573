<template>
    <div>
        <v-data-table
            :headers="Headers"
            :items="Items"
            :hide-default-footer="!ToLinked"
            :item-class="getClassColor"
            :options.sync="pagination"
            :server-items-length="TotalItems"
            :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
                'page-text': `Página ${pagination.page} de ${
                    parseInt(TotalItems / pagination.itemsPerPage) + 1
                }`,
            }"
        >
            <template v-slot:[`item.value`]="{ item }">
                <span>{{ formatMoney(item.value) }}</span>
            </template>
            <template v-slot:[`item.date`]="{ item }">
                <span>{{ formatDate(item.date) }}</span>
            </template>
            <template v-slot:[`item.moviment_type`]="{ item }">
                <span>{{ getTypeMoviment(item.moviment_type) }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <span>{{ getStatus(item.status) }}</span>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <span>{{ getType(item.type) }}</span>
            </template>
            <template v-if="ToLinked" v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            v-on="on"
                            v-bind="attrs"
                            color="black"
                            class="mr-2"
                            @click="link(item)"
                            >mdi-link-variant</v-icon
                        >
                    </template>
                    <span>Vincular</span>
                </v-tooltip>
            </template>
            <template v-slot:[`item.ligations`]="{ item }">
                <span>{{
                    formatLigations(item.linked_to, item.total_vinculations)
                }}</span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { formatDate, formatMoney } from '@util/format_util'
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'PeriodConciliationBankMovimentsDatatable',
    props: {
        Headers: Array,
        Items: Array,
        TotalItems: Number || 1,
        // Pagination: Object,
        ToLinked: Boolean,
    },
    computed: {
        ...mapState('period_conciliation_bank_moviments', ['getClassColor']),
        ...mapState('period_conciliation_dialog_vinculation', [
            'callGetRecords',
        ]),
        ...mapFields('period_conciliation_dialog_vinculation', ['pagination']),
    },
    created() {
        console.log('DATATABLE BM', this.DefaultFooter)
        console.log('DATATABLE', this.Headers)
    },
    methods: {
       ...mapActions('period_conciliation_dialog_vinculation', [
            'linkRegistry',
            'getRecordsRelated',
        ]),
        link(item) {
            console.log('LINK', item)
            item.tab_linked = 3
            this.linkRegistry(item)
        },
        formatMoney(value) {
            return formatMoney(value)
        },
        formatDate(date) {
            return formatDate(date)
        },
        getTypeMoviment(value) {
            switch (value) {
                case 0:
                    return 'Manual'
                case 1:
                    return 'Pix'
                case 2:
                    return 'Tarifa'
                default:
                    return 'Não definido'
            }
        },
        getStatus(value) {
            switch (value) {
                case 2:
                    return 'Completa'
                case 3:
                    return 'Recusada'
                case 4:
                    return 'Em Processamento '
                default:
                    return 'Status desconhecido'
            }
        },
        getType(value) {
            switch (value) {
                case 0:
                    return 'Débito'
                case 1:
                    return 'Crédito'
                default:
                    return 'Não definido'
            }
        },
        formatLigations(linked_to, total_vinculations) {
            return `${linked_to} de ${total_vinculations}`
        },
    },
    watch: {
        pagination: {
            handler() {
                if (this.ToLinked && this.callGetRecords) {
                    console.log("GET RECORDS RELATED", this.callGetRecords)
                    this.getRecordsRelated()
                }
            },
        },
    },
}
</script>